

const BannerData= {
    AboutH2: "About",
    AboutImg: "img/banner-cover.avif",
    ServicesH2: "Services",
    ServicesImg: "img/banner-cover.avif",
    GalleryH2:"Gallery",
    GalleryImg:"img/banner-cover.avif",
    CareerH2:"Career",
    CareerImg:"img/banner-cover.avif",
    ContactH2:"Contact",
    ContactImg:"img/banner-cover.avif",
    AppointmentH2:"Appointment",
    AppointmentImg:"img/banner-cover.avif",
}

export default BannerData